import React from "react";
import { useEffect, useContext } from 'react';
import "./Tags.css";
import { TagCloud } from 'react-tagcloud'
import { ArtContext } from '../../ArtContext'

export default function Tags(props) {

  const tagValue = useContext(ArtContext);
  const setTagValue = useContext(ArtContext);  

  // tag data

  useEffect(() => {

    const getData = () => {
    fetch(
      `https://api.artcollection.net.au/tags?api_key=938215f469191c4c2b1ba07180c914f4&idClient=20`
    )
      .then((response) => response.json())
      .then((res) => {
        console.log('res data', res)
        console.log('value Data: ',res[0].value)          
        console.log('count Data: ',res[1].count)
        // setData([...data, ...res]);
      })
      .catch((err) => { });
    console.log('tag data', data) 

    // arrayToMultiArray(tagsData)
    // arraymorph(tagsData)

  };

    getData();
    
  }, []);
  
// TEMP DATA while array map nested array issue to solve
  const data = [
  {value: 'drawings', count: 815
},
{value: 'war', count: 36
},
{value: 'imagefix', count: 154
},
{value: 'east sydney technical college 1926-27', count: 10
},
{value: 'art students', count: 29
},
{value: ' art studios', count: 29
},
{value: 'commercial art', count: 46
},
{value: ' murals', count: 44
},
{value: ' illustration', count: 44
},
{value: 'figures', count: 201
},
{value: 'sculpture', count: 7
},
{value: 'nudes', count: 95
},
{value: 'watercolours', count: 297
},
{value: 'portraits', count: 191
},
{value: 'still-life', count: 20
},
{value: 'art institute of chicago october 1927-28', count: 14
},
{value: 'landscapes', count: 128
},
{value: 'head studies', count: 32
},
{value: 'parsons ny september 1928 - june 1929', count: 89
},
{value: 'roerich institute september 1929 - june 1930', count: 11
},
{value: 'animals', count: 114
},
{value: ' birds', count: 111
},
{value: 'boston 1931-34', count: 95
},
{value: 'design', count: 33
},
{value: 'moriah summer school july-august 1929', count: 16
},
{value: 'cityscapes', count: 56
},
{value: 'montreal june-november 1930', count: 1
},
{value: 'crowley/fizelle period', count: 74
},
{value: 'flexwood panel', count: 8
},
{value: 'religious subjects', count: 33
},
{value: 'gordon 36 nelson st 1950-92', count: 492
},
{value: 'tamworth new hampshire 1932', count: 5
},
{value: 'four-in-one-bird (pelican)', count: 7
},
{value: 'taos new mexico', count: 39
},
{value: 'taos new mexico august 1933', count: 35
},
{value: 'highlight', count: 23
},
{value: 'paintings', count: 255
},
{value: 's.s. city of rayville 1934', count: 7
},
{value: 'transportation', count: 61
},
{value: 'wollstonecraft sydney 1935-41', count: 300
},
{value: 'constructive abstracts i 1935-1940', count: 51
},
{value: 'constructive abstracts 2 1942-1953', count: 54
},
{value: 'prints', count: 38
},
{value: 'unconscious', count: 17
},
{value: 'art critics', count: 13
},
{value: 'crowley and fizelle', count: 21
},
{value: 'humour', count: 18
},
{value: 'beach scenes', count: 14
},
{value: 'gordon 32 nelson st 1945-50', count: 146
},
{value: 'blaxland wentworth and lawson 1938', count: 7
},
{value: 'p&o liner (strath) leaving the quay', count: 16
},
{value: 'flight (aeroplanes)', count: 16
},
{value: 'fishermen', count: 44
},
{value: 'dog gymkhana', count: 11
},
{value: 'flight (refugees)', count: 5
},
{value: 'architectural decoration', count: 12
},
{value: 'theatre', count: 52
},
{value: ' opera', count: 52
},
{value: 'wynyard station', count: 56
},
{value: 'music', count: 15
},
{value: ' dance', count: 14
},
{value: ' ballet', count: 14
},
{value: 'blake prize competition entries and studies', count: 16
},
{value: 'new guinea 1941', count: 12
},
{value: 'army life', count: 41
},
{value: 'bomber crash', count: 13
},
{value: 'amf in new guinea and australia 1941-44', count: 64
},
{value: 'canberra 1942', count: 77
},
{value: 'lewers\' farm emu plains 1940s', count: 22
},
{value: 'camouflage', count: 1
},
{value: 'bayonet drill', count: 15
},
{value: 'cyclists canberra', count: 21
},
{value: 'east sydney technical college teacher', count: 25
},
{value: '', count: 24
},
{value: 'constructive abstracts 3 1954-1992', count: 136
},
{value: 'luminal kinetics', count: 40
},
{value: 'mural', count: 1
},
{value: 'textiles', count: 6
},
{value: 'stained glass', count: 2
},
{value: 'memorabilia', count: 6
},
{value: 'sets and costumes', count: 23
},
{value: 'dance', count: 1
},
{value: 'ballet', count: 1
},
{value: 'birds', count: 2
},
{value: 'puppets', count: 6
},
{value: 'juvenilia', count: 1
},

  ]

// const arrayToMultiArray = (tagsData) => {
//     var matrix = [], i;
//     i = -1;
//     for (var key in tagsData) {
//         matrix.push({"value":key, "count":tagsData[key]});
//     }
        
//     setData([matrix]);
//    console.log('tagdata',data) 
// }

  
// const arraymorph = data => {
  
//     const input = {
//       "columnNames": [
//         "Value",
//         "Count"
//       ]
//     };

//   const output = {
//     reportResults: input.rows.map(row => Object.fromEntries(
//       input.columnNames.map((name, i) => [name, row[i]])
//     ))
//   }
//   console.log(output);

// };
    
  // set tag state

  const ClickHandler = (tag, setTagValue) => {
    
    setTagValue(tag); // NOT WORKING - Uncaught TypeError: setTagValue is not a function
    const newTagObj = tag;
    //setTagValue(newTagObj); // NOT WORKING - Uncaught TypeError: setTagValue is not a function
    console.log('setting tag to ' + tag)
  }  

  return (
    <ArtContext.Provider value={[tagValue, setTagValue]}>
    <div>
      <TagCloud
        minSize={12}
        maxSize={28}
        tags={data}
        disableRandomColor
            onClick={tag => {
              const newTag = tag;
            // this.setTagValue(newTag); 
            ClickHandler(tag.value)
           // onClick={tag => alert(`'${tag.value}' was selected!`)
          }}
      />
    </div>
</ArtContext.Provider>
  );
}

