import React from "react";
import {
  Nav,
  Highlights,
  SearchBar,
} from "../components/index";
import { BackTop } from "antd";


export default function Home() {
  return (
    <div>
        <Nav />
      <div className="Home">
        <h1>Explore the world.</h1>
        <SearchBar
        marginTop="48px"
        placeholder="Search artworks..."
        />
      </div>

      <div className="Artworks">
        <Highlights></Highlights>
      </div>
       <BackTop />
    </div>
  );
}

