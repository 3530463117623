import React from "react";
import Masonry from "react-masonry-component";
import InfiniteScroll from "react-infinite-scroll-component";
import "../../App.css";
import { masonryOptions } from "../../Config";
import { Card, Space } from 'antd';

export default function Highlights() {

const [imagesData, setImagesData] = React.useState([]);

  const [imageModal, setImageModal] = React.useState({
    showModal: false,
    modalSrc: null,
    imageIndex: null,
    currentSectionLength: null
  });

  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch(
      `https://api.artcollection.net.au/artworks?api_key=938215f469191c4c2b1ba07180c914f4&page=${
        page + 1
      }`
    )
      .then((response) => response.json())
      .then((res) => {
        setImagesData([...imagesData, ...res]);
      })
      .catch((err) => {});
  };

  const fetchData = () => {
    setPage(page + 1);
    getData();
  };

  const refresh = () => {
    console.log("refresh.....");
  };

  const onSet = (type) => {
    if (type == "prev") {
      if (imageModal.imageIndex != 0) {
        let data = imagesData[imageModal.imageIndex - 1];

        setImageModal((modal) => ({
          ...modal,
          imageIndex: imageModal.imageIndex - 1,
          modalSrc: data.largeimage
        }));
      } else {
        alert("No images remain");
      }
    } else if (type == "next") {
      if (imageModal.imageIndex > imagesData.length) {
        alert("No images remain");
      } else {
        let data = imagesData[imageModal.imageIndex + 1];

        setImageModal((modal) => ({
          ...modal,
          imageIndex: imageModal.imageIndex + 1,
          modalSrc: data.largeimage
        }));
      }
    }
  };


  return (
    <div>
         <InfiniteScroll
        dataLength={imagesData.length}
        next={fetchData}
        hasMore={true}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>You have viewed all the artworks</b>
          </p>
        }
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
        }
      >
        <Masonry
          className={"photo-list"}
          elementType={"ul"}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {imagesData &&
            imagesData.map((art, index) => (

          <li className={`photo-item`} key={index}>

                <Card bordered = {false}  hoverable style={{ width: 300}}>
                
                <img
                  src={art.thumbnail}
                  alt=""
                  onClick={() => {
                    setImageModal({
                      showModal: true,
                      modalSrc: art.largeimage,
                      imageIndex: index,
                      currentSectionLength: imagesData.length
                    });
                  }}
                  />
                  </Card>
               </li>

            ))}
        </Masonry>
      </InfiniteScroll>
  
      <div
        id="myModal"
        className="modal"
        style={{
          display: imageModal.showModal ? "block" : "none"
        }}
      >
        <div>
          <span
            className="close"
            onClick={() =>
              setImageModal((modal) => ({ ...modal, showModal: false }))
            }
          >
            &times;
          </span>

          <div
            className="mySlides"
            style={{ display: imageModal.showModal ? "block" : "none" }}
          >
            <img
              className="modal-content"
              id="img01"
              src={imageModal.modalSrc}
              alt=""
            />
          </div>

          <a href="#" className="prev" onClick={() => onSet("prev")}>
            &#10094;
          </a>
          <a href="#" className="next" onClick={() => onSet("next")}>
            &#10095;
          </a>

          <div />
        </div>
      </div>
        
       
    </div>
  );
}
