import React from "react";
import { Menu } from "antd";
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

function LeftMenu() {
  return (
    <Menu mode="horizontal">
      <Menu.Item key="catalogue" icon={<AppstoreOutlined />}>
        <Link to="/catalogue">Catalogue</Link>
      </Menu.Item>
      <Menu.Item key="about">
        <Link to="/about">About</Link>
    </Menu.Item>    
      <Menu.Item key="margel">
        Margel Hinder
      </Menu.Item>
    </Menu>
  );
}
export default LeftMenu;
