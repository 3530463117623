import React from "react";
import {
  Nav,
  ArtworkDetail,
  SearchBar,
} from "../components/index";
import { BackTop } from "antd";


export default function Artwork() {
  return (
    <div>
        <Nav />
      <div className="Home">
        <h1></h1>
      </div>

      <div className="Photos">
        <ArtworkDetail></ArtworkDetail>
          </div>
          <BackTop />
    </div>
  );
}
