import React from "react";
import { useContext } from 'react';
import {
  Nav,
  Artworks,
  SearchBar,
  Tags
} from "../components/index";
import { BackTop } from "antd";
import { ArtContext } from '../ArtContext'

export default function Catalogue() {

  const searchValue = useContext(ArtContext);
  const setSearchValue = useContext(ArtContext);

// search

  const onSubmitHandler = (event, setSearchValue) => {
    
  event.preventDefault();
    console.log(event.target.value);
    setSearchValue(event.target.value);
  // console.log(searchValue);
   
  };

  const onChangeHandler = (event, setSearchValue) => {
    console.log(event.target.value);
    setSearchValue(event.target.value);
  };


  return (
    <ArtContext.Provider value={[ searchValue, setSearchValue]}>>
    <div>
        <Nav />
      <div className="Home">
        <h1>Frank Hinder's Paintings</h1>
        <SearchBar
          marginTop="48px"
          placeholder="Search artworks..."
          onChangeHandler={onChangeHandler}
          onSubmitHandler={onSubmitHandler}
        />
      </div>
      <div className="Tagzone">
        <Tags />
      </div>

      <div className="Photos">

        <div className="photos-container">
          <div className="photos-container-top">
            <h1>Artworks</h1>
            {/* <Options handleOptionChange={this.handleOptionChange} /> */}
          </div>
        </div>
        <Artworks />
      </div>
      
          <BackTop />
      </div>
      </ArtContext.Provider>
  );
}
