import React from "react";
import { useState, useEffect, useContext } from 'react';
import Masonry from "react-masonry-component";
import InfiniteScroll from "react-infinite-scroll-component";
import "../../App.css";
import { masonryOptions } from "../../Config";
import { Card, Spin, Button } from 'antd';
import Icon, { HeartTwoTone, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import  { ArtContext }  from '../../ArtContext';


const { Meta } = Card;

const HeartSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
    <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
  </svg>
);

export default function Artwork () {
  
// state - Bring in Context?
  
  const tagValue = useContext(ArtContext);
  const searchValue = useContext(ArtContext);
  const setSearchValue = useContext(ArtContext);

  let [imagesData, setImagesData] = useState([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState('all');
 const [faves, setFaves] = useState([]);
//const { faves, setFaves } = useContext(ArtContext);

// retrieve faves in local storage
  useEffect(() => {
  const faves = JSON.parse(localStorage.getItem('MyfavArtworks'));
    if (faves) {
    setFaves(faves);
    }
  }, []);

// goto art detail page
  
  let navigate = useNavigate();

// faves  
  const toggleFav = (idArt) => {

    const tempFaves = faves.slice();
    const IndexArt = tempFaves.indexOf(idArt)

        if (IndexArt === -1) {
            tempFaves.push(idArt)
          console.log('Adding ' + idArt + ' to faves...')
          setFaves(tempFaves )

        } else { 
          setFaves(tempFaves.filter(item => item !== idArt))
          console.log('Removing ' + idArt + ' from faves...')
        }

       console.log(faves) 
  };

  // store faves in local storage 
  useEffect(() => {
      localStorage.setItem('MyfavArtworks', JSON.stringify(faves));
  }, [faves]);

// data

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    fetch(
      `https://api.artcollection.net.au/artist?api_key=938215f469191c4c2b1ba07180c914f4&page=${page + 1}`
     //  `https://api.artcollection.net.au/artist?api_key=938215f469191c4c2b1ba07180c914f4&page=${page + 1}&query=${searchValue}&api_key=${process.env.REACT_AC_APP_API_KEY}`;

    )
      .then((response) => response.json())
      .then((res) => {
        setImagesData([...imagesData, ...res]);
      })
      .catch((err) => {});
  };

  const fetchData = () => {
    setPage(page + 1);
    getData();
  }; 
  
  const refresh = () => {
    console.log("refreshing data...");
  };

// faves assets
  const HeartIcon = props => <Icon component={HeartSvg} {...props} />;
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#666' }} spin />;
  

  // filter faves
  const handleFilterClick = (filter) => {
    setFilter({ filter })
    console.log('setting filter to ' + filter)
    if (filter === 'faves') { filterfaves();} else {fetchData();} 
  }  

  const filterfaves = () => {
    let newImagesData = imagesData.filter(item => faves.includes(item.idArt))
    console.log(newImagesData)
    setImagesData(newImagesData);
    console.log(imagesData)
    // let finalData = (filter === 'faves') ? newImagesData : imagesData
  }; 


  return (
    <div className={`artCollection`} >

      <Button icon={<HeartTwoTone />} onClick={() => handleFilterClick('faves')} >Show Favourites</Button>

         <InfiniteScroll
        dataLength={imagesData.length}
        next={fetchData}
        hasMore={true}
        loader={<Spin indicator={antIcon} />}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>You have viewed all the artworks</b>
          </p>
        }
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={500}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
        }
      >
        <Masonry
          className={"photo-list"}
          elementType={"ul"}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          
            {imagesData &&
            imagesData.map((art, index) => (
              
              <li className={`photo-item`} key={index}>

                <Card
                  style={{ width: 300 }}
                  cover={<img alt="{art.title}"
                  src={art.thumbnail} />}
                  actions={[
                    <EditOutlined key="edit" onClick={() => {
                      navigate(`/catalogue/${art.idArt}`)
                    }} />,
                  faves.includes(art.idArt)? 
                    <HeartIcon style={{ color: 'hotpink', float:'right' }} onClick={() => { toggleFav(art.idArt) }} key="heartfull" />
                    :< HeartTwoTone twoToneColor="#eb2f96" onClick={() => { toggleFav(art.idArt) }} key="heartempty" />
                  ]}
                >
                  <Meta title={art.title + ' ' + art.yearText} description={art.medium} />                  
                </Card>
              </li>
            ))}
        </Masonry>
      </InfiniteScroll>
          
       
    </div>
  );
}
