import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { useParams } from "react-router-dom";
import Home from './pages/Home'
import Catalogue from './pages/Catalogue'
import Artwork from './pages/Artwork'
import About from './pages/About'
import Contact from './pages/Contact'
import Margel from './pages/Margel'
import { ArtContext } from './ArtContext';

function App() {
  return (
    <ArtContext.Provider value={[]}>
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/catalogue" element={<Catalogue />} />
        <Route path="/catalogue/:idArt" element={<Artwork />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/margel" element={<Margel />} />
      </Routes>
    </BrowserRouter>
      </div>
      </ArtContext.Provider>
  );
}

export default App;