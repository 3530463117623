import React from "react";
import {
  Nav,
  SearchBar,
} from "../components/index";
import { BackTop } from "antd";

export default function Contact() {
  return (
    <div>
        <Nav />
      <div className="Home">
        <h1>Contact</h1>
        <SearchBar
        marginTop="48px"
        placeholder="Search artworks..."
        />
      </div>

          <div className="Photos">
              <p>"form"</p>
          </div>
          <BackTop />
    </div>
  );
}
