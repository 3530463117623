import React, { createContext, useState } from 'react';

const ArtContext = createContext();

const ArtContextProvider = (props) => {
    const [faves, setFaves] = useState([]);
    const [tagValue, setTagValue] = useState("");
    const [searchValue, setSearchValue] = useState("");
    return (    
        <ArtContext.Provider value={[faves, tagValue, searchValue, setSearchValue, setFaves, setTagValue]}>
            {props.children}
        </ArtContext.Provider>
    );

};

export { ArtContext, ArtContextProvider };