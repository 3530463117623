import React from "react";
import { useState, useEffect } from 'react';
import "../../App.css";
import "./ArtworkDetail.css";
import Masonry from 'react-masonry-component';
import { masonryOptions } from "../../Config";
import { Image, Card, Space } from 'antd';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image-react-17";
import PropTypes from 'prop-types';


export default function ArtworkDetail() {
  
// state
  
  const [imagesData, setImagesData] = useState([]);
  const [imageModal, setImageModal] = useState({
    showModal: false,
    modalSrc: null,
    imageIndex: null,
    currentSectionLength: null
  });
  const [faves, setFaves] = useState([]);
  const [togFav, setTogFav] = useState(false);

// data
  
  let { idArt } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch(
      `https://api.artcollection.net.au/artwork?api_key=938215f469191c4c2b1ba07180c914f4&idArt=${idArt}`
    )
      .then((response) => response.json())
      .then((res) => {
        setImagesData([...imagesData, ...res]);
      })    
      .catch((err) => {});
  };
    console.log(imagesData);

  // const { art } = imagesData;
  return (

    <div>
      {imagesData.map((art, i) => i<1 && (
        <div className="photo-detail-container" key={idArt}>
          
        <div>

            <picture>
                <source media="(max-width: 1200px)" srcSet={art.largeimage} />
                <source media="(max-width: 250px)" srcSet={art.largeimage} />
                <ModalImage
                    small={art.largeimage}
                    large={art.largeimage}
                    alt={art.title}
                    className="photo-modal"
                    showRotate
                />
            </picture>
              
              {art.largeimage &&
              <div>
                <div className="photo-detail-info">
                  <div className="photo-detail-left">
                  <h3>{`${art.title} ${art.yearText}`}</h3>
                    <p>{art.medium}</p>
                    
                          </div>

                          <div className="photo-detail-right">
                              <DownloadOutlined />
                              <a className="photo-detail-download" href={art.largeimage} target="_blank" download> download</a>
                          </div>
                      </div>
                      <div className="photo-detail-statistics">

                      </div>
                      <div className="more">

                      </div>

                  </div>
              }
          </div>
      </div>

      ))}
  </div>
    
  );
}
