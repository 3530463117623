import React from "react";
import { Menu } from "antd";
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

function RightMenu() {
  return (
    <div>
      <Menu mode="horizontal">
        <Menu.Item key="margel">
          <Link to="/margel">Margel Hinder</Link>
        
      </Menu.Item>
      </Menu>
    </div>
  );
}
export default RightMenu;
