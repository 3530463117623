import React from "react";
import {
  Nav,
  Artworks,
  SearchBar,
} from "../components/index";
import { BackTop } from "antd";


export default function Margel() {
  return (
    <div>
        <Nav />

      <div className="Photos">
        <Artworks></Artworks>
          </div>
          <BackTop />
    </div>
  );
}
